import { mapPersonalDetails } from '~/utils/formatters';
import { omit } from 'lodash';
import { ref, computed, reactive, toRefs } from 'vue';
import { useFacilities } from '@/composables/use-facilities';
import { useSdk } from './use-mycure';

const LIST_LIMIT = 20;

function normalizePatient (patient) {
  const totalEncounters = patient?.$populated?.totalEncounters?.length;
  return {
    ...omit(patient, '$populated'),
    ...patient.$populated?.personalDetails,
    totalEncounters,
  };
}

const populateEncounters = {
  method: 'find',
  service: 'medical-encounters',
  foreignKey: 'patient',
  localKey: 'id',
}; // TODO: implement foreignOps field for easyjoey to allow use of $count for less computing power

const state = reactive({
  patients: [],
  totalNumberOfPatients: 0,
  totalNumberOfArchivedPatients: 0,
});

export function usePatients () {
  const sdk = useSdk();
  const { activeFacility } = useFacilities();
  const {
    archive: archivePatient,
    unarchive: unarchivePatient,
  } = usePatient();

  // const patients = ref([]);
  const patientsTotal = ref(0);
  const patientsPageTotal = ref(0);
  const limit = ref(LIST_LIMIT);

  const list = async (query = {}, infiteScroll) => {
    const facilityId = activeFacility.value?.id;
    if (!facilityId) return;

    query = {
      facility: facilityId,
      archivedAt: {
        $exists: false,
      },
      $total: true,
      $limit: limit.value,
      $sort: {
        createdAt: -1,
      },
      $populate: {
        personalDetails: {
          key: 'id',
          method: 'findOne',
          service: 'personal-details',
        },
        totalEncounters: populateEncounters,
      },
      ...query,
    };

    if (infiteScroll) {
      query.$skip = state.patients.length;
      query.$limit = 50;
    }
    const result = await sdk?.list('medical-patients', query);
    const mappedResult = result.data.map(normalizePatient).map(mapPersonalDetails);

    if (infiteScroll) {
      state.patients = state.patients.concat(mappedResult);
    } else {
      state.patients = mappedResult;
    }

    patientsTotal.value = result.total;
    patientsPageTotal.value = result.data.length;
    state.totalNumberOfPatients = result.total;

    return result?.data;
  };

  const create = async (data) => {
    try {
      const facilityId = activeFacility.value?.id;
      if (!facilityId) return;

      const payload = {
        facility: facilityId,
        ...data,
      };

      const result = await sdk?.create('medical-patients', payload);

      state.patients.unshift(mapPersonalDetails(normalizePatient(result)));
      patientsTotal.value += 1;
      state.totalNumberOfPatients += 1;

      return result;
    } catch (e) {
      console.error(e);
    }
  };

  const archive = async (id) => {
    await archivePatient(id);
    const patientIndex = state.patients.findIndex((patient) => patient.id === id);
    state.patients.splice(patientIndex, 1);
    state.totalNumberOfPatients -= 1;
    state.totalNumberOfArchivedPatients += 1;
  };

  const unarchive = async (id) => {
    await unarchivePatient(id);
    const patientIndex = state.patients.findIndex((patient) => patient.id === id);
    state.patients.splice(patientIndex, 1);
    state.totalNumberOfPatients += 1;
    state.totalNumberOfArchivedPatients -= 1;
  };

  // NOTE: totalNumberOfArchivedPatients is hot fix
  async function getTotalNumberOfPatients () {
    // totalNumberOfPatients
    const facilityId = activeFacility.value?.id;
    if (!facilityId) return;

    const activePatientsQuery = {
      facility: facilityId,
      archivedAt: {
        $exists: false,
      },
      $total: true,
    };

    const activePatientsResult = await sdk?.list('medical-patients', activePatientsQuery);
    state.totalNumberOfPatients = activePatientsResult.total;

    // totalNumberOfArchivedPatients

    const archivedPatientsQuery = {
      facility: facilityId,
      archivedAt: {
        $exists: true,
      },
      $total: true,
    };

    const archivedPatientsResult = await sdk?.list('medical-patients', archivedPatientsQuery);
    state.totalNumberOfArchivedPatients = archivedPatientsResult.total;
  }

  function clearState () {
    state.patients = [];
  }

  return {
    patientsTotal,
    patientsPageTotal,
    limit,
    ...toRefs(state),
    archive,
    unarchive,
    list,
    create,
    clearState,
    getTotalNumberOfPatients,
  };
}

const patientState = reactive({
  patient: null,
  formattedName: null,
  formattedAddress: null,
  formattedSex: null,
  formattedPhoneNumbers: null,
  formattedAge: null,
  formattedAgeInMonths: null,
  formattedDateOfBirth: null,
});

export function usePatient () {
  const sdk = useSdk();

  patientState.formattedName = computed(() => patientState.patient?.formattedName);
  patientState.formattedAddress = computed(() => patientState.patient?.formattedAddress);
  patientState.formattedSex = computed(() => patientState.patient?.formattedSex);
  patientState.formattedPhoneNumbers = computed(() => patientState.patient?.formattedPhoneNumbers);
  patientState.formattedAgeInMonths = computed(() => patientState.patient?.formattedAgeInMonths);
  patientState.formattedAge = computed(() => patientState.patient?.formattedAge);
  patientState.formattedDateOfBirth = computed(() => patientState.patient?.formattedDateOfBirth);
  const picURL = computed(() => patientState.patient?.picURL);
  const email = computed(() => patientState.patient?.email);
  const workNo = computed(() => patientState.patient?.workNo);
  const homeNo = computed(() => patientState.patient?.homeNo);
  const mobileNo = computed(() => patientState.patient?.mobileNo);
  const isArchived = computed(() => patientState.patient?.archivedAt);

  async function get (id) {
    const query = {
      id,
      $populate: {
        personalDetails: {
          key: 'id',
          method: 'findOne',
          service: 'personal-details',
        },
      },
    };
    const result = await sdk?.get('medical-patients', query);
    patientState.patient = mapPersonalDetails(normalizePatient(result));
    return result;
  };

  async function archive (id) {
    try {
      const result = await sdk?.update('medical-patients', id, {
        archive: true,
      });
      get(id);
      return result;
    } catch (e) {
      console.error(e);
    }
  };

  async function unarchive (id) {
    try {
      await sdk?.update('medical-patients', id, {
        archive: false,
      });
      get(id);
    } catch (e) {
      console.error(e);
    }
  };

  async function setPatient (patient) {
    patientState.patient = patient;
  }

  return {
    picURL,
    email,
    workNo,
    homeNo,
    mobileNo,
    isArchived,
    ...toRefs(patientState),
    get,
    unarchive,
    archive,
    setPatient,
  };
}
